// import Table from "./components/Table"
import { useState, useEffect } from "react"
import data from "./mock-data.json"
import { nanoid } from "nanoid"
import Modal from "react-modal"
Modal.setAppElement("#root") //modal için

function App() {
  const [companies, setCompanies] = useState(data)
  const [status, setStatus] = useState("All")
  useEffect(() => {
    status === "All"
      ? setCompanies(data)
      : setCompanies(data.filter((company) => company.status === status))
  }, [status])
  const [modalIsOpen, setModalIsOpen] = useState(false) //Modal
  const [addFormData, setAddFormData] = useState({
    name: "",
    tax: "",
    amount: "",
    status: "",
  })
  const handleAddFormChange = (e) => {
    e.preventDefault()

    const fieldName = e.target.getAttribute("name")
    const fieldValue = e.target.value

    const newFormData = { ...addFormData }
    newFormData[fieldName] = fieldValue

    setAddFormData(newFormData)
  }

  const handleAddFormSubmit = (e) => {
    e.preventDefault()
    const newCompany = {
      id: nanoid(),
      name: addFormData.name,
      tax: addFormData.tax,
      amount: addFormData.amount,
      status: addFormData.status,
    }
    const newCompanies = [...companies, newCompany]
    setCompanies(newCompanies)
  }
  // Modal Style
  const customStyles = {
    content: {
      border: "none",
      backgroundColor: "transparent",
    },
  }

  return (
    <div className="container mx-auto px-4 sm:px-8">
      <div className="py-2">
        {/* Create New Invoice */}
        <Modal
          style={customStyles}
          isOpen={modalIsOpen}
          onRequestClose={() => setModalIsOpen(false)}
        >
          <div className="flex  items-center justify-center">
            <div className="grid bg-white rounded-lg shadow-xl w-11/12 md:w-9/12 lg:w-1/2">
              <div className="flex justify-center py-4">
                <div className="flex bg-purple-200 rounded-full md:p-4 p-2 border-2 border-purple-300">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="#ffffff"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  >
                    <path d="M16 4h2a2 2 0 0 1 2 2v14a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2h2"></path>
                    <rect x="8" y="2" width="8" height="4" rx="1" ry="1"></rect>
                  </svg>
                </div>
              </div>

              <div className="flex justify-center">
                <div className="flex">
                  <h1 className="text-gray-600 font-bold md:text-2xl text-xl">
                    Add Invoice
                  </h1>
                </div>
              </div>
              <form onSubmit={handleAddFormSubmit}>
                <div className="grid grid-cols-1 mt-5 mx-7">
                  <label className="uppercase md:text-sm text-xs text-gray-500 text-light font-semibold">
                    Company Name
                  </label>
                  <input
                    name="name"
                    className="py-2 px-3 rounded-lg border-2 border-purple-300 mt-1 focus:outline-none focus:ring-2 focus:ring-purple-600 focus:border-transparent"
                    type="text"
                    placeholder="ex. Apple"
                    onChange={handleAddFormChange}
                  />
                </div>

                <div className="grid grid-cols-1 md:grid-cols-2 gap-5 md:gap-8 mt-5 mx-7">
                  <div className="grid grid-cols-1">
                    <label className="uppercase md:text-sm text-xs text-gray-500 text-light font-semibold">
                      Tax
                    </label>
                    <input
                      name="tax"
                      className="py-2 px-3 rounded-lg border-2 border-purple-300 mt-1 focus:outline-none focus:ring-2 focus:ring-purple-600 focus:border-transparent"
                      type="text"
                      placeholder="ex. 40%"
                      onChange={handleAddFormChange}
                    />
                  </div>
                  <div className="grid grid-cols-1">
                    <label className="uppercase md:text-sm text-xs text-gray-500 text-light font-semibold">
                      Amount
                    </label>
                    <input
                      name="amount"
                      className="py-2 px-3 rounded-lg border-2 border-purple-300 mt-1 focus:outline-none focus:ring-2 focus:ring-purple-600 focus:border-transparent"
                      type="text"
                      placeholder="ex. 1.000₺"
                      onChange={handleAddFormChange}
                    />
                  </div>
                </div>

                <div className="grid grid-cols-1 mt-5 mx-7">
                  <label className="uppercase md:text-sm text-xs text-gray-500 text-light font-semibold">
                    Status Selection
                  </label>
                  <select
                    name="status"
                    className="py-2 px-3 rounded-lg border-2 border-purple-300 mt-1 focus:outline-none focus:ring-2 focus:ring-purple-600 focus:border-transparent"
                    onChange={handleAddFormChange}
                  >
                    <option>Paid</option>
                    <option>Outstanding</option>
                    <option>Late</option>
                  </select>
                </div>
                <div className="flex items-center justify-center  md:gap-8 gap-4 pt-5 pb-5">
                  <button
                    className="w-auto bg-purple-500 hover:bg-purple-700 rounded-lg shadow-xl font-medium text-white px-4 py-2"
                    type="submit"
                  >
                    Create
                  </button>
                  <button
                    onClick={() => setModalIsOpen(false)}
                    className="w-auto bg-red-500 hover:bg-red-700 rounded-lg shadow-xl font-medium text-white px-4 py-2"
                    type="submit"
                  >
                    Cancel
                  </button>
                </div>
              </form>
            </div>
          </div>
        </Modal>
        {/* Create New Invoice End */}
        <button
          onClick={() => setModalIsOpen(true)}
          className="w-full flex-2 sm:w-auto px-6 py-3   mb-4  text-base   font-semibold leading-6 focus:ring-2 focus:ring-offset-2 focus:ring-offset-white focus:ring-gray-900 focus:outline-none transition-colors duration-200  rounded-xl block  border-b border-green-300  bg-green-200 hover:bg-green-300 text-green-900"
        >
          Add New Invoice
        </button>
        <div className="flex space-x-4">
          <FilterButton
            handleSetStatus={setStatus}
            name="All"
            className="w-full flex-1 sm:w-auto px-6 py-3   mb-4  text-base   font-semibold leading-6 focus:ring-2 focus:ring-offset-2 focus:ring-offset-white focus:ring-gray-900 focus:outline-none transition-colors duration-200  rounded-xl block  border-b border-green-300  bg-green-200 hover:bg-green-300 text-green-900"
          >
            All
          </FilterButton>
          <FilterButton
            handleSetStatus={setStatus}
            name="Paid"
            className="w-full flex-1 sm:w-auto px-6 py-3   mb-4  text-base   font-semibold leading-6 focus:ring-2 focus:ring-offset-2 focus:ring-offset-white focus:ring-gray-900 focus:outline-none transition-colors duration-200  rounded-xl block  border-b border-green-300  bg-green-200 hover:bg-green-300 text-green-900"
          >
            Paid
          </FilterButton>
          <FilterButton
            handleSetStatus={setStatus}
            name="Outstanding"
            className="w-full flex-1 sm:w-auto px-6 py-3   mb-4  text-base   font-semibold leading-6 focus:ring-2 focus:ring-offset-2 focus:ring-offset-white focus:ring-gray-900 focus:outline-none transition-colors duration-200  rounded-xl block  border-b border-yellow-300  bg-yellow-200 hover:bg-yellow-400 text-green-900"
          >
            Outstanding
          </FilterButton>
          <FilterButton
            handleSetStatus={setStatus}
            name="Late"
            className="w-full flex-1 sm:w-auto px-6 py-3   mb-4  text-base   font-semibold leading-6 focus:ring-2 focus:ring-offset-2 focus:ring-offset-white focus:ring-gray-900 focus:outline-none transition-colors duration-200  rounded-xl block  border-b border-red-500  bg-red-600 hover:bg-red-800 text-green-900"
          >
            Late
          </FilterButton>
        </div>
        <div className="-mx-4 sm:-mx-8 px-4 sm:px-8 py-4 overflow-x-auto">
          <div className="inline-block min-w-full shadow rounded-lg overflow-hidden">
            <table className="min-w-full leading-normal">
              <thead>
                <tr>
                  <th className="px-8 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                    Company Name
                  </th>
                  <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                    Tax
                  </th>
                  <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                    Amount
                  </th>
                  <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                    Status
                  </th>
                  <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                    Change
                  </th>
                </tr>
              </thead>

              <tbody>
                {companies.map((company) => (
                  <tr>
                    <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                      <div className="flex items-center">
                        <div className="ml-3">
                          <p className="text-gray-900 whitespace-no-wrap">
                            {company.name}
                          </p>
                        </div>
                      </div>
                    </td>
                    <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                      <p className="text-gray-900 whitespace-no-wrap">
                        {company.tax}
                      </p>
                    </td>
                    <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                      <p className="text-gray-900 whitespace-no-wrap">
                        {company.amount}
                      </p>
                    </td>
                    <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                      <span className="relative inline-block px-3 py-1 font-semibold text-green-900 leading-tight">
                        <span
                          aria-hidden
                          className="absolute inset-0 bg-green-200 opacity-50 rounded-full"
                        ></span>
                        <span className="relative">{company.status}</span>
                      </span>
                    </td>
                    <td className=" border-b border-gray-200 bg-white text-sm">
                      <div className="flex item-center ml-7">
                        <div classNames="w-4 mr-2 transform hover:text-purple-500 hover:scale-110">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth="2"
                              d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
                            />
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth="2"
                              d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z"
                            />
                          </svg>
                        </div>
                        <div classNames="w-4 mr-2 transform hover:text-purple-500 hover:scale-110">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth="2"
                              d="M15.232 5.232l3.536 3.536m-2.036-5.036a2.5 2.5 0 113.536 3.536L6.5 21.036H3v-3.572L16.732 3.732z"
                            />
                          </svg>
                        </div>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  )
}

const FilterButton = ({ name, handleSetStatus }) => {
  return (
    <button
      className="w-full flex-2 sm:w-auto px-6 py-3   mb-4  text-base   font-semibold leading-6 focus:ring-2 focus:ring-offset-2 focus:ring-offset-white focus:ring-gray-900 focus:outline-none transition-colors duration-200  rounded-xl block  border-b border-green-300  bg-green-200 hover:bg-green-300 text-green-900"
      onClick={() => handleSetStatus(name)}
    >
      {" "}
      {name}
    </button>
  )
}

export default App
